import React, { useState } from "react";

function Contact() {
    //Email form
    const [emailForm, setEmailForm] = useState({
        name: "",
        email: "",
        subject: "",
        message: "",
      });
    
      //Result if message was sent or not
      const [result, setResult] = useState("");
    
      //Status of while message is being sent
      const [status, setStatus] = useState("Send");
    
      function resetEmailForm() {
        setEmailForm({ name: "", email: "", subject: "", message: "" });
      }
    
      function handleEmailFormChange(event) {
        setEmailForm((prevEmailData) => {
          return {
            ...prevEmailData,
            [event.target.name]: event.target.value,
          };
        });
    
        if (result.length > 0) {
          setResult("");
        }
      }

      const handleSubmit = async (e) => {
        setResult('');
        e.preventDefault();
        setStatus('Sending...');
    
        const { name, email, subject, message } = e.target.elements;
    
        let details = {
            name: name.value,
            email: email.value,
            subject: subject.value,
            message: message.value,
        };
    
        //https://red-architectural-drafting-896917cbab40.herokuapp.com/
        //https://kormos-manufacturing-a58bf1aa475d.herokuapp.com/
        //http://localhost:5000/send
    
        try {
            let response = await fetch('https://red-architectural-drafting-896917cbab40.herokuapp.com/send', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json;charset=utf-8',
                },
                body: JSON.stringify(details),
            });
            setStatus('Send');
            let result = await response.json();
    
            if (result.status === 'success') {
                setResult('Message Sent!');
                resetEmailForm();
            } else if (result.status === 'fail') {
                alert('Message failed to send.');
            }
        } catch (error) {
            console.error(error);
            setStatus('Send');
            setResult('Issues with submitting message.');
        }
    };

  return (
    <div className="contact-cont">
      <div>
        Please feel free to let us know about your project and any questions you
        may have. We'll do our best to get back to you in a timely manner.
      </div>
      <br></br>
      <div className="contact-form-cont">
        <form id="contact-form" className="contact-form" onSubmit={handleSubmit} method='POST'>
          <input
            placeholder="Name*"
            type="text"
            name="name"
            required={true}
            value={emailForm.name}
            onChange={handleEmailFormChange}
          />
          <input
            placeholder="Email*"
            type="email"
            name="email"
            required={true}
            value={emailForm.email}
            onChange={handleEmailFormChange}
          />
          <input
            placeholder="Subject"
            type="subject"
            name="subject"
            required={false}
            value={emailForm.subject}
            onChange={handleEmailFormChange}
          />
          <textarea
            maxLength={300}
            placeholder="Message (max 300 characters)*"
            name="message"
            required={true}
            value={emailForm.message}
            onChange={handleEmailFormChange}
          />
          <button className="submit-button" type="submit">
            {status}
          </button>
          <h4>{result}</h4>
        </form>
      </div>
    </div>
  );
}

export default Contact;
