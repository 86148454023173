import React, { useState } from "react";

function Services() {

  const [servActive, setServActive] = useState(0);

  function setServ(ind) {
    ind === servActive ? setServActive(0) : setServActive(ind);
  }

  return (
    <div className="services-cont serv">
        <div className="services-services-cont">
      <div onClick={() => setServ(1)}
          className={servActive === 1 ? "card-serv-exp" : "card-serv"}>Development Permit Drawings <span className={servActive === 1 ? "down-arrow-act" : "down-arrow"}>&#x25BC;</span>
      <p>Some construction projects in Calgary require a development permit, depending on land-use bylaw and regulations.</p></div>
      <div onClick={() => setServ(2)}
          className={servActive === 2 ? "card-serv-exp" : "card-serv"}>Building Permit Drawings <span className={servActive === 2 ? "down-arrow-act" : "down-arrow"}>&#x25BC;</span>
      <p>A building permit will be required for almost all types of construction projects.</p></div>
      <div onClick={() => setServ(3)}
          className={servActive === 3 ? "card-serv-exp" : "card-serv"}>As-Built (Record) Drawings <span className={servActive === 3 ? "down-arrow-act" : "down-arrow"}>&#x25BC;</span>
      <p>Accurate drawings of existing buildings are required for most renovations and development projects.</p></div>
      <div onClick={() => setServ(4)}
          className={servActive === 4 ? "card-serv-exp" : "card-serv"}>CRU Drawings <span className={servActive === 4 ? "down-arrow-act" : "down-arrow"}>&#x25BC;</span>
      <p>Required for renovation of commercial lease spaces.</p></div>
      <div onClick={() => setServ(5)}
          className={servActive === 5 ? "card-serv-exp" : "card-serv"}>Design Drawings <span className={servActive === 5 ? "down-arrow-act" : "down-arrow"}>&#x25BC;</span>
      <p>Design drawings can help explore different options for a project, prior to committing to more detailed drawings.</p></div>
      <div onClick={() => setServ(6)}
          className={servActive === 6 ? "card-serv-exp" : "card-serv"}>Schematic Drawings/Plans <span className={servActive === 6 ? "down-arrow-act" : "down-arrow"}>&#x25BC;</span>
      <p>Experimentation with different layouts, landscape, furniture, or just need a base, generic floor plan.</p></div>
      </div>
      <br></br>
      <br></br>
      <hr></hr>
      <br></br>
      <div className="most-types">
        Most types of architectural drawings are available upon request. <br></br><br></br>Please
        give us a call to review your project.<br></br><br></br> We will work with you to
        establish the best route forward in expediting your project.
      </div>
    </div>
  );
}

export default Services;
