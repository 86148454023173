import './App.css';

// components
import Header from "./Components/Header/Header";
import Footer from "./Components/Footer/Footer";

import HomePage from "./Components/Pages/HomePage";
import About from "./Components/Pages/About";
import Services from "./Components/Pages/Services";
import Contact from "./Components/Pages/Contact";

// assets
import "./Assets/css/Header.css";
import "./Assets/css/Footer.css";
import "./Assets/css/HomePage.css";
import "./Assets/css/About.css";
import "./Assets/css/Services.css";
import "./Assets/css/Contact.css";

import { Route, Routes } from "react-router-dom";

function App() {
  return (
    <>
    <Header />
    <Routes>
      <Route path="/" element={<HomePage/>} />
      <Route path="/about" element={<About/>} />
      <Route path="/services" element={<Services/>} />
      <Route path="/contact" element={<Contact/>} />
    </Routes>
    <Footer />
    </>
  );
}

export default App;
