function About() {
  return (
    <div className="about-cont">
        <img className="graph-paper" src="graph-paper.jpg"></img>
        <img className="little-house" src="house.jpg"></img>
      <div className="about-text">
      <div>
        RED Architectural Drafting is a small Calgary based business
        specializing in architectural drafting for home owners, contractors, and
        real estate developers.
      </div>
      <br></br>
      <div>
        RED was started by Robert Davies, a qualified architectural
        technologist, having attended SAIT. Since then, Robert has accrued over
        25 years of experience in the building industry, working with both
        private firms and local government.
      </div>
      <br></br>
      <div>Robert has worked on a variety of projects:</div>
      <br></br>
      <ul>
        <li>Commercial/Retail developments</li>
        <li>Schools</li>
        <li>Multi-family developments</li>
        <li>Single family dwellings</li>
        <li>Commercial renovations</li>
        <li>Residential renovations/additions</li>
        <li>Residential secondary suites</li>
        <li>Garages, decks</li>
      </ul>
      <br></br>
      <hr></hr>
      <br></br>
      <div>
        RED Architectural can navigate and simplify the permit process for clientele. RED strives to stay up-to-date with current & emerging codes,
        innovative building products, and current technology.
      </div>
      </div>
    </div>
  );
}

export default About;
