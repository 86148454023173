import First from "../Assets/images/tiptonsuite.png";
import Second from "../Assets/images/egerts-retreat-living-room.webp";
import Third from "../Assets/images/egerts-retreat-kitchen.webp";
import Fourth from "../Assets/images/egerts-retreat-bathroom2.webp";
import Fifth from "../Assets/images/garage.png";

export default [
{
    title: "From Blueprints...",
    description: "(Secondary Suite)",
    urls: First,
},
{
    title: "to Realizations...",
    description: "(Secondary Suite)",
    urls: Second,
},
{
    title: "to Realizations...",
    description: "(Secondary Suite)",
    urls: Third,
},
{
    title: "to Realizations...",
    description: "(Secondary Suite)",
    urls: Fourth,
},
{
    title: "From Blueprints...",
    description: "(Garage)",
    urls: Fifth,
},
];