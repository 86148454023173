import React, { useEffect, useState } from "react";
import SliderContent from "../SliderContent";
import Dots from "../Dots";
import Arrows from "../Arrows";
import sliderImage from "../sliderImage";
import "../../Assets/css/slider.css";

const len = sliderImage.length - 1;

function HomePage(props) {
  const [activeIndex, setActiveIndex] = useState(0);

  const [servActive, setServActive] = useState(0);

  function setServ(ind) {
    ind === servActive ? setServActive(0) : setServActive(ind);
  }

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveIndex(activeIndex === len ? 0 : activeIndex + 1);
    }, 5000);
    return () => clearInterval(interval);
  }, [activeIndex]);

  return (
    <>
      <div className="slider-container">
        <SliderContent activeIndex={activeIndex} sliderImage={sliderImage} />
        <Arrows
          prevSlide={() =>
            setActiveIndex(activeIndex < 1 ? len : activeIndex - 1)
          }
          nextSlide={() =>
            setActiveIndex(activeIndex === len ? 0 : activeIndex + 1)
          }
        />
        <Dots
          activeIndex={activeIndex}
          sliderImage={sliderImage}
          onclick={(activeIndex) => setActiveIndex(activeIndex)}
        />
      </div>
      <div className="budget-cont">
        Budget Friendly Architectural Drafting for Home Owners, Contractors, and
        Real Estate Developers.
      </div>
      <hr></hr>
      <div className="RED-desc">
        RED Architectural is a small Calgary based business committed to providing its clients
        with architectural drawings to bring their renovation, building, project, or ideas to life. 
        RED Architectural can guide you through your project from concept to reality, ensuring you have
        all the correct permits in place to avoid any problems during construction. If you need a building permit
        or a development permit, no job is too small. RED Architectural has lots of experience in getting
        the right permits in place. 
        <br></br><br></br>
        RED specializes in residential projects such as legalizing existing secondary suites, 
        developing new secondary suites, decks, additions, and custom garages. Contact us for a free
        consultation and proposal.
      </div>
      <div className="residential-cont">
        Residential <br></br>&<br></br> Commercial
      </div>
      <div className="residential-img"></div>
      <div className="services-cont">
        <div className="services-home">Services</div>
        <div
          onClick={() => setServ(1)}
          className={servActive === 1 ? "card-serv-exp" : "card-serv"}
        >
          Development Permit Drawings{" "}
          <span className={servActive === 1 ? "down-arrow-act" : "down-arrow"}>&#x25BC;</span>
          <p>
            Some construction projects in Calgary require a development permit,
            dependent on land-use bylaw and regulations.
          </p>
        </div>
        <div
          onClick={() => setServ(2)}
          className={servActive === 2 ? "card-serv-exp" : "card-serv"}
        >
          Building Permit Drawings <span className={servActive === 2 ? "down-arrow-act" : "down-arrow"}>&#x25BC;</span>
          <p>
            A building permit will be required for almost all types of
            construction projects.
          </p>
        </div>
        <div
          onClick={() => setServ(3)}
          className={servActive === 3 ? "card-serv-exp" : "card-serv"}
        >
          As-Built (Record) Drawings{" "}
          <span className={servActive === 3 ? "down-arrow-act" : "down-arrow"}>
          &#x25BC;
          </span>
          <p>
            Accurate drawings of existing buildings are required for most
            renovations and development projects.
          </p>
        </div>
        <div
          onClick={() => setServ(4)}
          className={servActive === 4 ? "card-serv-exp" : "card-serv"}
        >
          CRU Drawings <span className={servActive === 4 ? "down-arrow-act" : "down-arrow"}>&#x25BC;</span>
          <p>Commercial retail unit drawings are required for renovation of commercial lease spaces.</p>
        </div>
        <div
          onClick={() => setServ(5)}
          className={servActive === 5 ? "card-serv-exp" : "card-serv"}
        >
          Design Drawings <span className={servActive === 5 ? "down-arrow-act" : "down-arrow"}>&#x25BC;</span>
          <p>
            Design drawings can help explore different options for a project,
            prior to committing to more detailed drawings.
          </p>
        </div>
        <div
          onClick={() => setServ(6)}
          className={servActive === 6 ? "card-serv-exp" : "card-serv"}
        >
          Schematic Drawings/Plans <span className={servActive === 6 ? "down-arrow-act" : "down-arrow"}>&#x25BC;</span>
          <p>
            Schematic drawings are good for experimenting with different layouts; e.g., landscape and furniture.
          </p>
        </div>
      </div>
    </>
  );
}

export default HomePage;
