
function Footer() {
  return (
    <div>
      <hr className="red-line"></hr>
      <img className="ratri1" src="right-angle-tri.svg"></img>
      <div className="footer-cont">
        <div className="footer-contact-info">
        <div>Phone: +1 (403) 282-8662</div>
        <div>Cell: +1 (587) 229-7293</div>
        <div>Email: roberted@telus.net</div>
        </div>
        <br></br>
        <div className="copyright-cont">
          Copyright&nbsp;<span>&#169;</span>&nbsp;2024&nbsp;R&nbsp;&#x2022;&nbsp;E&nbsp;&#x2022;&nbsp;D Architectural &nbsp;|&nbsp;  Powered by&nbsp;
           <a
            className="web-design-link"
            href="https://www.simplewebsitedesign.ca"
            target="_blank"
            rel="noreferrer"
          >
            simplewebsitedesign.ca
          </a>
        </div>
      </div>
    </div>
  );
}

export default Footer;
