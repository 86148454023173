import { Link } from "react-router-dom";

function Header() {
  return (
    <header>
    <div className="red-cont">
  <div className="r-e-d">R&nbsp;&#x2022;&nbsp;E&nbsp;&#x2022;&nbsp;D</div>
  <div className="architectural-drafting">ARCHITECTURAL DRAFTING</div>
  </div>
  <div className="header-routes">
    <div className="home"><Link to="/" className="nav-links">Home</Link></div>
    <div className="about"><Link to="/about" className="nav-links">About</Link></div>
    <div className="services"><Link to="/services" className="nav-links">Services</Link></div>
    <div className="contact"><Link to="/contact" className="nav-links">Contact</Link></div>
  </div>
  </header>
  );
}

export default Header;
